





import { defineComponent } from '@vue/composition-api';
import { getForwardPath, gotoLinkPage } from '@/services';

export default defineComponent({
  name: 'IconGotoLink',
  props: {
    link: String
  },
  setup(props, { emit }) {
    const onLinkClick = () => {
      if (!props.link) return;
      try {
        const { internal, path } = getForwardPath(props.link);
        emit('emitSendNclick');
        if (internal) {
          gotoLinkPage(path);
        } else {
          window.location.href = path;
        }
      } catch (e) {
        return;
      }
    };
    return {
      onLinkClick
    };
  }
});
