






































import {
  computed,
  ComputedRef,
  defineComponent,
  onUnmounted,
  reactive,
  toRefs,
  watch
} from '@vue/composition-api';
import store from '@/store/index';
import SerializeInfo from '@/components/molecules/contents/SerialInfo.vue';
import GenreInfo from '@/components/molecules/contents/GenreInfo.vue';
import ProductionInfo from '@/components/molecules/contents/ProductionInfo.vue';
import Synopsis from '@/components/molecules/contents/Synopsis.vue';
import IconTypeSpan from '@/components/atoms/icons/IconTypeSpan.vue';
import { ContentInfo, Genre } from '@/models';
import {
  IconType,
  ICON_OPEN,
  TYPE_ICONS
} from '@/commons/constants/icon-types';

export default defineComponent({
  name: 'ContentsDetail',
  components: {
    SerializeInfo,
    GenreInfo,
    ProductionInfo,
    Synopsis,
    IconTypeSpan
  },
  props: {
    contentInfo: ContentInfo,
    showDetail: Object,
    introTheme: String
  },
  setup(props, { emit }) {
    const { dispatch, getters } = store;
    const content: ComputedRef<ContentInfo> = computed(
      () => props.contentInfo as ContentInfo
    );
    const genres: ComputedRef<Genre[]> = computed(() => content.value.genres);
    const serialInfo = reactive({
      serialInfo: content.value?.publishDay,
      usageLevel: content.value?.restrictedAge
    });
    const synopsis: ComputedRef<string | undefined> = computed(
      () => content.value?.description
    );
    const showDetailInfo: ComputedRef<IconType> = computed(
      () => props.showDetail as IconType
    );
    const isWide: ComputedRef<boolean> = computed(
      () => getters['UIStore/isWide']
    );
    const close = () => dispatch('ContentsHomeStore/showDetailContent');
    const sendGotoGenre = (genre: Genre) => emit('emitGotoGenre', genre);

    const hideBodyScroll = (data: IconType) => {
      // 모바일 > 상세정보시 작품홈 스크롤 제거
      document.body.style.overflowY =
        data.type === ICON_OPEN.type && !isWide.value ? 'hidden' : '';
    };

    watch(showDetailInfo, hideBodyScroll);

    onUnmounted(() => {
      document.body.style.overflowY = '';
    });

    return {
      content,
      synopsis,
      TYPE_ICONS,
      genres,
      showDetailInfo,
      close,
      sendGotoGenre,
      ...toRefs(serialInfo)
    };
  }
});
