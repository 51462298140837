import { KO_KR } from "@/commons/constants/config-language";
import { Language } from "@/commons/constants/enum";
import { dateConvert, setTimeToken, timeValue, toSingular } from "@/services";

export class CommentAuthor {
	userId: number;
	nickname: string;
	profileImageUrl: string;
	constructor(props: CommentAuthor) {
		this.userId = props.userId;
		this.nickname = props.nickname;
		this.profileImageUrl = props.profileImageUrl;
	}
	getNickName(): string | number {
		return this.nickname;
	}
	getProfileUrl(): string {
		return this.profileImageUrl;
	}
};

export class CommentReaction {
	liked: boolean;
	reported: boolean;
	constructor(props: CommentReaction) {
		this.liked = props.liked;
		this.reported = props.reported;
	}
};

export class Comment {
	id: number;
	author: CommentAuthor;
	comment: string;
	totalLikes: number;
	reaction: CommentReaction;
	postedAt: string;

	constructor(props: Comment) {
		this.id = props.id;
		this.author = new CommentAuthor(props.author);
		this.comment = props.comment;
		this.totalLikes = props.totalLikes;
		this.reaction = new CommentReaction(props.reaction);
		this.postedAt = props.postedAt;
	}

	postedAtToStr(lang: Language) {
		const date = dateConvert(this.postedAt);
		const { time, type } = date;
		switch (type) {
			case 'sec':
			case 'min':
				const minToken = `${time} ${toSingular(+time, timeValue[0], lang)}`;
				return `${setTimeToken('posted_ago', minToken)}`;
			case 'hour':
				const hourToken = `${time} ${toSingular(+time, timeValue[1], lang)}`;
				return `${setTimeToken('posted_ago', hourToken)}`;
			case 'day':
				const dayToken = `${time} ${toSingular(+time, timeValue[2], lang)}`;
				return `${setTimeToken('posted_ago', dayToken)}`;
			case 'date':
				try {
					if (typeof time === 'number') {
						const current = new Date(time);
						if (lang === KO_KR) {
							return `${current.getFullYear()}${timeValue[4]} ${current.getMonth() + 1
								}${timeValue[3]} ${current.getDate()}${timeValue[2]} `;
						} else {
							return current.toLocaleString(lang, {
								year: 'numeric',
								month: 'short',
								day: 'numeric'
							});
						}
					} else {
						return time + '';
					}
				} catch (e) {
					return time + '';
				}
		}
	}
};

export class HistoryComment extends Comment {
	contentType: string;
	contentId: number;
	contentName: string;
	mature: boolean;
	chapterId: number;
	chapterName: string;
	chapterThumbnail: { url: string };
	status: string;

	constructor(props: HistoryComment) {
		super(props);
		this.contentType = props.contentType;
		this.contentId = props.contentId;
		this.contentName = props.contentName;
		this.mature = props.mature;
		this.chapterId = props.chapterId;
		this.chapterName = props.chapterName;
		this.chapterThumbnail = props.chapterThumbnail;
		this.status = props.status;
	}
}