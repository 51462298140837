



































import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  watch
} from '@vue/composition-api';
import Store from '@/store/index';
import EventBannerList from '@/components/molecules/contents-elements/EventBannerList.vue';
import SectionNavigator from '@/components/molecules/navigators/SectionNavigator.vue';
import H from '@/components/atoms/templates/H.vue';
import IconTypeAnchor from '@/components/atoms/icons/IconTypeAnchor.vue';
import {
  nextBtnState,
  SectionHelperService
} from '@/services/home/section.helper';
import { Element, Section } from '@/models';
import { IconType, ICON_VIEWMORE } from '@/commons/constants/icon-types';
import { sendNclick, gotoLinkPage, getForwardPath } from '@/services';

export default defineComponent({
  name: 'BannerListSection',
  components: {
    H,
    IconTypeAnchor,
    EventBannerList,
    SectionNavigator
  },
  props: {
    section: Object
  },
  setup(props) {
    const { getters } = Store;
    const sectionInfo: ComputedRef<Section> = computed(
      () => props.section as Section
    );
    const elements: ComputedRef<Element[]> = computed(() =>
      sectionInfo.value && sectionInfo.value.elements
        ? sectionInfo.value.elements
        : []
    );
    const elementUiType: ComputedRef<
      | 'content_basic'
      | 'content_big'
      | 'banner_list'
      | 'genre_basic'
      | 'banner_image'
      | undefined
    > = computed(() => {
      return sectionInfo.value && sectionInfo.value.elementUiType
        ? sectionInfo.value.elementUiType
        : undefined;
    });

    const isWide: ComputedRef<boolean> = computed(
      () => getters['UIStore/isWide']
    );
    const MAX_SLIDE_LEN = SectionHelperService.getSectionMaxLen(
      elementUiType.value
    );
    const liWidth = SectionHelperService.getLiWidth(
      sectionInfo.value.elementUiType
    );

    const ulRef: any = ref(null);

    let currentIndex = 1;
    let currentX = 0;
    let value = Math.floor(elements.value.length / MAX_SLIDE_LEN);
    let namoji = Math.floor(elements.value.length % MAX_SLIDE_LEN);
    const prev = ref(false);
    const next = ref(nextBtnState(value, namoji));

    const resetIndex = () => {
      try {
        currentIndex = 1;
        currentX = 0;
        value = Math.floor(elements.value.length / MAX_SLIDE_LEN);
        namoji = Math.floor(elements.value.length % MAX_SLIDE_LEN);
        prev.value = false;
        next.value = nextBtnState(value, namoji);
        ulRef.value.style.transform = `translateX(0px)`;
      } catch (e) {
        //
      }
    };

    const sendDirection = ({ type }: IconType) => {
      if (type.toLowerCase() === 'prev') {
        gotoPrev();
      } else {
        gotoNext();
      }
    };

    const gotoPrev = () => {
      if (currentIndex > 1) {
        currentX = currentX - MAX_SLIDE_LEN * liWidth;
        ulRef.value.style.transform = `translateX(-${currentX}px)`;
        currentIndex = currentIndex - 1;
        next.value = true;
        prev.value = currentX !== 0;
      } else {
        if (namoji > 0) {
          currentX = currentX - namoji * liWidth;
          ulRef.value.style.transform = `translateX(-${currentX}px)`;
          next.value = true;
        }
        prev.value = false;
      }
    };

    const gotoNext = () => {
      if (currentIndex === value) {
        if (namoji > 0) {
          currentX = currentX + namoji * liWidth;
          ulRef.value.style.transform = `translateX(-${currentX}px)`;
          prev.value = true;
        }
        next.value = false;
      } else {
        currentIndex = currentIndex + 1;
        currentX = currentX + MAX_SLIDE_LEN * liWidth;
        ulRef.value.style.transform = `translateX(-${currentX}px)`;
        prev.value = true;
        next.value = !(namoji === 0 && currentIndex === value);
      }
    };

    const sendBanner = (element: Element) => {
      try {
        if (!element.urlHttp) return;
        const { internal, path } = getForwardPath(element.urlHttp);
        if (internal) {
          gotoLinkPage(path);
        } else {
          window.location.href = path;
        }
        if (internal) {
          sendNclick(
            'home',
            'home.card',
            '' + element.id,
            '',
            sectionInfo.value.elementUiType +
              '&sectionid=' +
              sectionInfo.value.id +
              '&id=' +
              element.id
          );
        }
      } catch (e) {
        //
      }
    };

    const sendViewMore = () => {
      if (sectionInfo.value && sectionInfo.value.urlHttp) {
        sendNclick(
          'home',
          'home.more',
          '',
          '',
          sectionInfo.value.elementUiType + '&sectionid=' + sectionInfo.value.id
        );
        const { internal, path } = getForwardPath(sectionInfo.value.urlHttp);
        if (internal) {
          gotoLinkPage(path);
        } else {
          window.location.href = path;
        }
      }
    };

    watch(elements, () => {
      resetIndex();
    });

    return {
      sectionInfo,
      elements,
      elementUiType,
      isWide,
      ICON_VIEWMORE,
      ulRef,
      next,
      prev,
      sendDirection,
      sendBanner,
      sendViewMore
    };
  }
});
