interface Types {
  name: string;
  link: string;
  classes?: string;
  innerLink?: boolean;
}

interface Apps {
  types: Types;
  os: string;
}

interface Infos {
  apps: Apps[];
  sns: Types[];
  services: Types[];
}

interface Country {
  'ko-KR': Infos;
  'ja-JP': Infos;
  'en-US': Infos;
  'zh-TW'?: Infos;
  'fr-FR': Infos;
  'de-DE'?: Infos;
}

interface FooterInterface {
  FooterInfo: Country;
}

const EN_APPS = [
  {
    types: {
      name: 'Google play',
      link: 'https://play.google.com/store/apps/details?id=io.comico',
      classes: 'link_google'
    },
    os: 'aos'
  },
  {
    types: {
      name: 'App store',
      link: 'https://apps.apple.com/us/app/id1492654676',
      classes: 'link_appstore'
    },
    os: 'ios'
  }
];

const EN_SNS = [
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/pocketcomics_official/',
    classes: 'link_instagram'
  },
  {
    name: 'Youtube',
    link: 'https://www.youtube.com/channel/UCjWW3i9YZfDzFObw_No7SrQ',
    classes: 'link_youtube'
  }
];

export const Properties: FooterInterface = {
  FooterInfo: {
    'ko-KR': {
      apps: [
        {
          types: {
            name: 'Google play',
            link:
              'https://play.google.com/store/apps/details?id=com.wisdomhouse.justoon',
            classes: 'link_google'
          },
          os: 'aos'
        },
        {
          types: {
            name: 'App store',
            link:
              'https://apps.apple.com/kr/app/%EC%BD%94%EB%AF%B8%EC%BD%94/id1504032168',
            classes: 'link_appstore'
          },
          os: 'ios'
        },
        {
          types: {
            name: 'One Store',
            link: 'http://onesto.re/0000736109',
            classes: 'link_onestore'
          },
          os: 'aos'
        }
      ],
      sns: [
        {
          name: 'Facebook',
          link: 'https://www.facebook.com/COMICO',
          classes: 'link_facebook'
        },
        {
          name: 'Twitter',
          link: 'https://twitter.com/Comico_Korea',
          classes: 'link_twit'
        },
        {
          name: 'Youtube',
          link: 'https://www.youtube.com/c/COMICO_KR',
          classes: 'link_youtube'
        },
        {
          name: 'Instagram',
          link: 'https://www.instagram.com/comico_kr/',
          classes: 'link_instagram'
        },
        {
          name: 'Naver Blog',
          link: 'https://blog.naver.com/comicokr',
          classes: 'link_blog'
        }
      ],
      services: [
        { name: '이용약관', link: '/legal/terms_of_use', innerLink: true },
        {
          name: '개인정보처리방침',
          link: '/legal/privacy_policy',
          innerLink: true
        },
        {
          name: '청소년보호정책',
          link: '/legal/youth_protection_policy',
          innerLink: true
        },
        {
          name: '사업자정보',
          link: 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1448115549'
        },
        {
          name: '연재문의',
          link: '/document/faq/detail?detail=%2Fdocument%2Fhelp%2F006%2F00905',
          innerLink: true
        },
        {
          name: '제휴문의',
          link: '/document/faq/detail?detail=%2Fdocument%2Fhelp%2F006%2F00908',
          innerLink: true
        }
      ]
    },
    'ja-JP': {
      apps: [
        {
          types: {
            name: 'Google play',
            link: 'https://play.google.com/store/apps/details?id=jp.comico',
            classes: 'link_google'
          },
          os: 'aos'
        },
        {
          types: {
            name: 'App store',
            link:
              'https://apps.apple.com/jp/app/comico-komiko-wu-liaode-dumerusumatokomikku/id721512660',
            classes: 'link_appstore'
          },
          os: 'ios'
        }
      ],
      sns: [
        {
          name: 'Facebook',
          link: 'https://www.facebook.com/comico.jp',
          classes: 'link_facebook'
        },
        {
          name: 'Twitter',
          link: 'https://twitter.com/comico_jp',
          classes: 'link_twit'
        }
      ],
      services: [
        // { name: 'comicoとは', link: 'https://www.comico.jp/lp/app/index.nhn' },
        { name: '利用規約', link: '/legal/terms_of_use' },
        { name: '個人情報保護', link: '/legal/privacy_policy' },
        {
          name: '資金決済法に基づく表示 ',
          link: '/legal/fund_settlement'
        },
        {
          name: '特定商取引法の表示',
          link: '/legal/special_commercial_transaction'
        },
        { name: 'ヘルプ', link: '/document/faq', innerLink: true }
      ]
    },
    'en-US': {
      apps: EN_APPS,
      sns: EN_SNS,
      services: [
        { name: 'Help', link: '/document/faq', innerLink: true },
        { name: 'Terms of use', link: '/legal/terms_of_use', innerLink: true },
        {
          name: 'Privacy policy',
          link: '/legal/privacy_policy',
          innerLink: true
        }
      ]
    },
    'zh-TW': {
      apps: EN_APPS,
      sns: [
        {
          name: 'Facebook',
          link: 'https://www.facebook.com/PocketComic',
          classes: 'link_facebook'
        },
        {
          name: 'Twitter',
          link: 'https://twitter.com/comicspocket',
          classes: 'link_twit'
        },
        {
          name: 'Youtube',
          link: 'https://www.youtube.com/channel/UCjWW3i9YZfDzFObw_No7SrQ',
          classes: 'link_youtube'
        }
      ],
      services: [
        { name: '常見問題', link: '/document/faq', innerLink: true },
        { name: '使用者條款', link: '/legal/terms_of_use', innerLink: true },
        {
          name: '個人情報保護',
          link: '/legal/privacy_policy',
          innerLink: true
        }
      ]
    },
    'fr-FR': {
      apps: EN_APPS,
      sns: EN_SNS,
      services: [
        { name: 'Aide', link: '/document/faq', innerLink: true },
        {
          name: 'Conditions d\'utilisation',
          link: '/legal/terms_of_use',
          innerLink: true
        },
        {
          name: 'Politique de confidentialité',
          link: '/legal/privacy_policy',
          innerLink: true
        }
      ]
    },
    'de-DE': {
      apps: EN_APPS,
      sns: EN_SNS,
      services: [
        { name: 'Hilfe', link: '/document/faq', innerLink: true },
        {
          name: 'Nutzungsbedingungen',
          link: '/legal/terms_of_use',
          innerLink: true
        },
        {
          name: 'Datenschutzrichtlinie',
          link: '/legal/privacy_policy',
          innerLink: true
        }
      ]
    }
  }
};

interface PurchaseTermsInterface {
  title: string;
  message: string[];
}

export const PurchaseTerms: Array<PurchaseTermsInterface> = [
  {
    title: '결제오류 관련',
    message: [
      '일부 카드사의 정책으로 5만원~10만원 이상의 상품은 결제가 어려울 수 있습니다.',
      '관련 문의사항은 [1:1 문의하기]로 문의주시면 정확하게 확인하여 안내드리도록 하겠습니다.'
    ]
  },
  {
    title: '구매방식 및 사용범위',
    message: [
      '구매한 콘텐츠는 ‘앱, 모바일웹, PC웹’ 모든 플랫폼에서 언제든지 다시 감상할 수 있습니다.',
      '대여 구매한 경우, 결제시점으로부터 대여기간 동안 감상하실 수 있습니다.',
      '소장 구매한 경우, 언제든지 다시 감상하실 수 있습니다.',
      '모든 코인 상품은 부가세가 포함된 가격입니다.'
    ]
  },
  {
    title: '작품 구매취소',
    message: [
      '소장구매 : 소장한 화를 열람하지 않은 경우, 구매일로부터 7일 이내에 구매취소가 가능합니다.',
      '대여구매 : 대여한 화를 열람하지 않은 경우, 구매일로부터 대여기간동안 구매취소가 가능합니다.'
    ]
  },
  {
    title: '코인 결제취소 및 환불',
    message: [
      '충전한 코인상품을 이용하지 않은 경우, 결제일로부터 7일 이내 결제취소가 가능합니다. (단, 휴대폰 결제의 경우 당월만 취소 가능하며, 익월에는 7일 이내라도 청구요금 수납 확인 후 휴대폰 결제자 본인 계좌로 환불이 가능합니다.)',
      '잔여 코인 환불은 약관 제11조의 내용에 따라 10% 환불수수료가 공제됩니다.',
      '단, 잔여 코인은 구매하신 상품에서 보너스 코인 수를 제외한 기준으로 산정됩니다.',
      '환불 금액은 구매하신 상품가격 기준으로 적용됩니다. (단, 이벤트 상품은 기존 상품가격 기준으로 적용)',
      '코인사용 시, ‘iOS, 안드로이드, 웹’ 플랫폼 구분 없이 유효기간 임박 순서대로 소진됩니다.',
      '포인트전환으로 충전하신 코인상품은 환불이 불가능합니다.'
    ]
  }
];
