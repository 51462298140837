



















import {
  reactive,
  toRefs,
  defineComponent,
  computed,
  ref
} from '@vue/composition-api';
import Store from '@/store/index';
import { getLanguageCookie } from '@/helpers/cookies';
import ErrorDialog from '@/dialogs/ErrorDialog.vue';
import GlobalHeader from '@/components/organisms/headers/GlobalHeader.vue';
import GlobalFooter from '@/components/organisms/footers/GlobalFooter.vue';
import Dimmed from '@/components/molecules/dimmed/Dimmed.vue';
import { FooterUI } from '@/models';
import { Language } from './commons/constants/enum';
import { fbq } from './services/common/facebook-pixel.service';
import router from './router';
import { isIPhone } from './services/common/utils';
import { getLocale } from '@/commons/i18n';
import { Comment } from '@/store/common';
//import { Anniversary } from '@/store/anniversary';
import { Campaign } from '@/store/campaign';

export default defineComponent({
  name: 'LayoutDefault',
  components: {
    GlobalHeader,
    ErrorDialog,
    GlobalFooter,
    Dimmed
  },
  metaInfo() {
    let routeNameStr = this.$route.name as string;
    let routeNameLowerCase = routeNameStr == null ? this.$t('web_app_meta_title').toString() as string : routeNameStr.toLowerCase();
    if (routeNameLowerCase == 'policyversion') {
      routeNameLowerCase = "policy";
    }
    const metaName = 'web_app_meta_page_name.';
    let _metaDataNameKey = '';
    switch (routeNameLowerCase) {
      case "policy":
        _metaDataNameKey = metaName + routeNameLowerCase + "." + this.$route.params.type as string;
        break;
      case "challengeranking":
        _metaDataNameKey = metaName + routeNameLowerCase + "." + this.$route.params.rankingType as string;
        break;
      default:
        _metaDataNameKey = metaName + routeNameLowerCase;
        break;
    }

    let titleLeadName = this.$t(_metaDataNameKey).toString() as string;
    if (titleLeadName == _metaDataNameKey) {
      titleLeadName = this.$t('web_app_meta_title').toString() as string;
    }
    let _title = '';
    let _description = '';
    let concatName = '';
    switch (routeNameLowerCase) {
      case "faqlist":
      case "noticelist":
      case "faqdetail":
      case "noticedetail":
      case "policy":
        _title = titleLeadName;
        break;
      case "searchkeyword":
        concatName = titleLeadName + ":" + this.$route.params.q as string;
        break;
      case "challengesearch":
        concatName = titleLeadName + ":" + this.$route.params.keyword as string;
        break;
      default:
        if(ref(getLocale()).value === 'ja-JP'){
          _title = titleLeadName + " | " + this.$t('heading.h1.comico').toString() as string;
        }else{
          _title = titleLeadName;
        }
        break;
    }

    if (concatName != '') {
      if(ref(getLocale()).value === 'ja-JP'){
        _title = concatName + " | " + this.$t('heading.h1.comico').toString() as string;
      }else{
        _title = concatName;
      }
      _description = concatName + " | " + this.$t('web_app_meta_description').toString() as string;
    }

    if (_description == '') {
      _description = titleLeadName + " | " + this.$t('web_app_meta_description').toString() as string;
    }

    return {
      title: _title,
      meta: [
        { vmid: 'description', name: 'description', content: _description }
      ],
      link: [
        { rel: 'canonical', href: window.location.origin + this.$route.path}
      ]
    };
  },
  props: {
    footer: Boolean
  },
  setup(props, { root }) {
    const { dispatch, commit, getters } = Store;
    const footers: FooterUI = reactive({ footerRef: null });
    const language: Language = getLanguageCookie();
    const serviceAppkey = computed(
      () => getters['GlobalStore/getServiceAppkey']
    );
    const isChallenge = computed(
      () => router.currentRoute.fullPath === '/challenge'
    );
    const isShowGlobalFooter = computed(() => {
      return !Comment.isCommentPage && !Campaign.is_entered;
    });
    const tweak = () => {
      if (footers.footerRef) {
        const offset = footers.footerRef.$el.clientHeight;
        return {
          minHeight: offset ? `calc(100vh - ${offset}px)` : '100vh'
        };
      }
    };

    const checkWebview = (appType: string): boolean =>
      window.navigator.userAgent.includes(appType);

    const checkserviceAppkey = () => {
      switch (true) {
        case checkWebview('comicoKrApp-one_store'):
          return 'comicoKrApp-one_store';
        case checkWebview('comicoGlobalApp'):
          return 'comicoGlobalApp';
        case checkWebview('comicoKrApp'):
          return 'comicoKrApp';
        case checkWebview('comicoJpApp'):
          return 'comicoJpApp';
        default:
          return null;
      }
    };

    const acceptMatureInit = (): void => {
      if (
        (root['$isKo'] || root['$isJa']) &&
        !sessionStorage.getItem('acceptMature')
      ) {
        sessionStorage.setItem('acceptMature', 'Y');
      }
    };

    window.addEventListener('resize', () => {
      commit('UIStore/windowWidth', window.innerWidth);
    });

    dispatch('I18nStore/CHANGE_LANGUAGE', language);
    dispatch('GlobalStore/config', 'category');
    dispatch('GlobalStore/config', 'language');

    commit('DialogStore/dialog', root.$q.dialog);
    commit('DialogStore/notify', root.$q.notify);
    commit('GlobalStore/setServiceAppkey', checkserviceAppkey());

    acceptMatureInit();

    fbq.searchKeyword();
    return { ...toRefs(footers), tweak, serviceAppkey, isChallenge, isIPhone, language, isShowGlobalFooter };
  }
});
