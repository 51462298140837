

















































































































import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import BannerLi from '@/components/atoms/contents-notice/BannerLi.vue';
import PromotionLi from '@/components/atoms/contents-notice/PromotionLi.vue';
import IconTypeSpan from '@/components/atoms/icons/IconTypeSpan.vue';
import {
  ICON_COIN_BACK,
  ICON_DISCOUNT,
  ICON_EVENT_FREE,
  ICON_NOTICE,
  ICON_GAUGE_TIME
} from '@/commons/constants/icon-types';
import { TYPE_PROMOTION } from '@/commons/constants/promotion-types';
import { Banner, Promotion } from '@/models';
import { getForwardPath } from '@/services/contents/contents.helper';
import { gotoLinkPage } from '@/services';
export default defineComponent({
  name: 'ContentsNoticeModule',
  components: {
    BannerLi,
    PromotionLi,
    IconTypeSpan
  },
  props: {
    banners: Array,
    promotions: Array
  },
  setup(props, { emit }) {
    const bannerList: ComputedRef<Banner[]> = computed(
      () => props.banners as Banner[]
    );
    const promotionList: ComputedRef<Promotion[]> = computed(
      () => props.promotions as Promotion[]
    );
    const sendNclick = (node: Banner) => emit('emitSendBannerNclick', node);
    const sendNotice = (node: Banner) => {
      if (!node.webLinkUrl) return;
      sendNclick(node);
      const { internal, path } = getForwardPath(node.webLinkUrl);
      if (internal) {
        gotoLinkPage(path);
      } else {
        window.location.href = path;
      }
    };
    return {
      ICON_EVENT_FREE,
      ICON_COIN_BACK,
      ICON_DISCOUNT,
      ICON_NOTICE,
      ICON_GAUGE_TIME,
      TYPE_PROMOTION,
      // merged,
      bannerList,
      promotionList,
      sendNotice,
      sendNclick
    };
  }
});
