
// 단수 & 복수
export const useSingular = (time: number, addDateTxt: string, lang: string) => {
	// if(lang === 'en-US') {
	// 	return time === 1
	// 		? `${time} ${addDateTxt.slice(0, -1)}`
	// 		: `${time} ${addDateTxt}`;
	// }
	if (lang === 'en-US' || lang === 'fr-FR') {
		return `${time} ${toSingular(time, addDateTxt, lang)}`
	} else {
		return `${time}${addDateTxt}`;
	}
};

export const toSingular = (num: number, txt: string, lang: string) => {
	if ((lang === 'en-US' || lang === 'fr-FR' || lang === 'de-DE') && num === 1) {
		return txt.slice(0, -1);
	} else {
		return txt;
	}
}

export const removeHtmlTag = (str: string) => str.replace(/(<([^>]+)>)/gi, '');

export const lineBreak = (str: string) => str.replace(/[\r\n|\n]/gi, '<br/>');

export const ContextHelperService = {
	useSingular,
	toSingular,
	lineBreak,
	removeHtmlTag
};