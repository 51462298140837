





















import {
  computed,
  ComputedRef,
  defineComponent,
  Ref,
  ref
} from '@vue/composition-api';
import { imgToCanvas, isPC } from '@/services/common/utils';
import { ContentsHelperService } from '@/services/contents/contents.helper';
import { ChapterImage } from '@/models';
import { Encrypto } from '@/services';

export default defineComponent({
  name: 'ToCanvas',
  props: {
    imageIdx: String,
    image: Object
  },
  setup(props, { emit }) {
    const imageInfo: ComputedRef<ChapterImage> = computed(
      () => props.image as ChapterImage
    );
    const decodeUrl: ComputedRef<string | undefined> = computed(() => {
      const { parameter, url } = imageInfo.value;
      const imgUrl = Encrypto.AESDecoder(url, Encrypto.AESKey);
      return `${imgUrl}?${parameter}`;
    });
    const idx: ComputedRef<string | undefined> = computed(() => props.imageIdx);
    const imgRef: Ref<HTMLImageElement | null> = ref(null);
    const divRef: Ref<HTMLDivElement | null> = ref(null);
    const showImg = ref(true);
    const innerWidth = window.innerWidth;
    const wrapWidth = ref(isPC() ? 720 : window.innerWidth);
    const wrapHeight = ref(0);

    const toCanvas = () => {
      try {
        if (divRef.value && imgRef.value) {
          const canvas = imgToCanvas(imgRef.value);
          if (canvas?.canvas) {
            wrapWidth.value = canvas.clientWidth;
            wrapHeight.value = canvas.clientHeight;
            // divRef.value.appendChild(canvas.canvas);
            const backgroundOption = `
              background: url('${decodeUrl.value}');
              background-size: ${wrapWidth.value}px ${wrapHeight.value}px;
            `;
            if (idx.value) {
              ContentsHelperService.imageStyleAdd(idx.value, backgroundOption);
            }
            showImg.value = false;
          }
        }
        emit('emitLoadCount', {
          height: imgRef.value?.clientHeight
        });
      } catch (e) {
        emit('emitErrorLoad', {});
      }
    };

    return {
      decodeUrl,
      imgRef,
      showImg,
      divRef,
      innerWidth,
      wrapWidth,
      wrapHeight,
      toCanvas
    };
  }
});
