import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { HomeInfo } from '@/models';
import { CONTENT_TYPES } from '@/commons/constants/content.types';
import { getContentCategory, HomeService, setContentCategory } from '@/services/index';

export interface HomeStateInterface<T> {
  content: T;
  contentCategory: string;
}

/**
 * HomeStore
 */
export const HomeStore: Module<HomeStateInterface<HomeInfo>, RootState> = {
  namespaced: true,
  state: {
    content: {} as HomeInfo,
    contentCategory: getContentCategory(),
  },
  getters: {
    content(state) {
      return state.content;
    },
    popups(state) {
      return state.content.popups;
    },
    sections(state) {
      return state.content.sections;
    },
    topBanner(state) {
      return state.content.topBanner;
    },
    menu(state) {
      return state.content.menu;
    },
    contentCategory(state) {
      return state.contentCategory;
    },
    contentType(state) {
      switch(state.contentCategory) {
        case 'all_comic':
        default:
          return 'comic';
        case 'all_novel':
          return 'novel';
      }
    }
  },
  mutations: {
    content(state, value: HomeInfo) {
      state.content = value;
    },
    contentCategory(state, value: string) {
      state.contentCategory = value;
      setContentCategory(value);
    }
  },
  actions: {
    async initHomeContent({ commit }, { contentCategory }: {
      contentCategory: string
    }) {
      const data = await HomeService.getHomeContent(contentCategory);
      const result = new HomeInfo(data);
      commit('content', result);
      commit('contentCategory', contentCategory);
      return result;
    }
  }
};
